import React, { useEffect,useState } from "react";
import {  Table } from "antd";
import {Message,Language} from "./core"
const { Column } = Table;

export const MessageList = () => {
  const messageAPI = new Message()
  const languageAPI = new Language()
  const [data,setData] = useState()
  useEffect(async () => {
    await messageAPI.findAll()
    await languageAPI.findAll()
    const languages =  languageAPI.get()
    const dataSource = messageAPI.get()
    setData(dataSource.map(row => {
      row.isCurrent = row.isCurrent ? "true": "false"
      row.isVerified = row.isVerified ? "true": "false"
      row.isDisplayed = row.isDisplayed ? "true": "false"
      row.language =languages.find(language => language.id === row.language)?.name
      return row
    }))
    // setData(messageAPI.get())
  },[])
  return(
    <Table dataSource={data}>
      <Column title="ID" dataIndex="id" key="id" />
      <Column title="Serial" dataIndex="serialNumber" key="serialNumber" />
      <Column title="Title" dataIndex="title" key="title" />
      <Column title="Author" dataIndex="author" key="author" />
      <Column title="isCurrent" dataIndex="isCurrent" key="isCurrent" />
      <Column title="isVerified" dataIndex="isVerified" key="isVerified" />
      <Column title="isDisplayed" dataIndex="isDisplayed" key="isDisplayed" />
      <Column title="Language" dataIndex="language" key="language" />
      <Column title="Written Date" dataIndex="writtenDate" key="writtenDate" />
      <Column title="Message" dataIndex="messageBody" key="messageBody" width="500px"/>
      <Column title="Updated By" dataIndex="updatedBy" key="updatedBy" />
      <Column title="Verified By" dataIndex="verifiedBy" key="verifiedBy" />
      <Column title="Created At" dataIndex="createdAt" key="createdAt" />
      <Column title="Updated At" dataIndex="updatedAt" key="updatedAt" />
    </Table>
  )
}


export default MessageList