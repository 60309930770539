import { instance } from "./request"
export default class Message {
  constructor() {
    this.baseUrl = "messages"
    this.axios = instance
    this.messages = []
    // this.
  }
  async find(date, language) {
    const message = await this.axios.get(`${this.baseUrl}?date=${date}&language=${language}`)
    return message
  }
  async findAll() {
    const _this = this
    await this.axios.get(`${this.baseUrl}`)
      .then(function (response) {
        _this.messages = response.data
      })
  }
  async create(message) {
    await this.axios.post(`${this.baseUrl}`, message)
      .then(function (response) {
        console.log(response)
      })
  }
  get() {
    return this.messages
  }
}