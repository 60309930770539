import { instance } from "./request"
export default class Language {
  constructor() {
    this.baseUrl = "languages"
    this.axios = instance
    this.languages = []
  }
  async findAll() {
    const _this = this
    await this.axios.get(`${this.baseUrl}`)
      .then(function (response) {
        _this.languages = response.data
      })
  }
  get() {
    return this.languages
  }
}