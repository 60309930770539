import React from "react";
import { Tabs } from "antd";
import {DailySandesh} from "./DailySandesh.jsx";
import {SpecialSandesh} from "./SpecialSandesh.jsx";
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

export const SandeshForm =  (props) => {
  return(
    <Tabs defaultActiveKey="1" onChange={callback} tabBarGutter={20}>
      <TabPane tab="" disabled key="0">
      </TabPane>
      <TabPane tab="Daily Sandesh" key="1">
        <DailySandesh appRef={props.appRef} onFormUpdate={props.onFormUpdate} setLanguage={props.setLanguage}
          shouldAllowSave={props.shouldAllowSave} saveFormattedDate={props.saveFormattedDate}/>
      </TabPane>
      <TabPane tab="Special Sandesh" disabled key="2">
        <SpecialSandesh onFormUpdate={props.onFormUpdate}/>
      </TabPane>
    </Tabs>
  )
};
