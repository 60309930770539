import React, { useState } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import moment from "moment"
import "./Form.css"
const Option = Select.Option;
const { TextArea } = Input;
import mapper from "./assets/languageMapper.json"
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 4 },
}

export const DailySandesh = (props) => {
  const { translations, weekDays, languages } = mapper
  const [form] = Form.useForm()
  const [dateDetails, setDateDetails] = useState({ weekDay: "", date: "" })
  const [description, setDescription] = useState("")
  const [signature, setSignature] = useState("")
  const [, setFormValues] = useState()
  const [currentLanguage, setCurrentLanguage] = useState({})  
  
  const formFieldsToMap = [
    "language",
    "title",
    "body",
    "author"
  ]
  const onFinish = (event) => {
    let formattedBody = form.getFieldValue("body").replace(/"/g, "'")
    formattedBody = formattedBody.replace(/\*/g, "'")
    formattedBody = formattedBody.replace(/'+/g, "'")
    let re = new RegExp(`[ *][${currentLanguage.fullStop}*]`, "g")
    formattedBody = formattedBody.replace(re, currentLanguage.fullStop)
    re = new RegExp(`\\${currentLanguage.fullStop}+`, "g")
    formattedBody = formattedBody.replace(re, currentLanguage.fullStop + " ")
    re = new RegExp(`\\${currentLanguage.fullStop} '`, "g")
    formattedBody = formattedBody.replace(re, currentLanguage.fullStop + "'")
    //0x201C -> 0x2018
    //0x201D -> 0x2019
    formattedBody = formattedBody
      .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u2017]|\u201A|\u201B|\u201E|\u201F[\u2020-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "");
    formattedBody = formattedBody
      .replace(/(\u201C)/g, "\u2018");
    formattedBody = formattedBody
      .replace(/(\u201D)/g, "\u2019");
    formattedBody = formattedBody.replace(/\n/g, " ")
    formattedBody = formattedBody.replace(/  +/g, " ")
    formattedBody = formattedBody.replace(/,[ +]/g, ",")
    formattedBody = formattedBody.replace(/,/g, ", ")
    form.setFieldsValue({
      "date": form.getFieldValue("date")
    })
    props.onFormUpdate({ ...event, ...dateDetails, description, body: formattedBody.trim(), signature })
    setFormValues(event)
    props.saveFormattedDate(form.getFieldValue("date")
      .set({"hour": 0, "minute": 0, "second":0})
      .format("YYYY-MM-DD HH:mm:ss"))
    props.shouldAllowSave(true)  
    // const momentRef = moment();
    // const TODAY = momentRef.clone().startOf("day");
    // const YESTERDAY = momentRef.clone().subtract(1, "days").startOf("day");
    // const DAY_BEFORE_YESTERDAY = momentRef.clone().subtract(2, "days").startOf("day");
    // const TOMORROW = momentRef.clone().add(1, "days").startOf("day");
    // if(form.getFieldValue("date").isSame(TODAY, "d") || 
    // form.getFieldValue("date").isSame(YESTERDAY, "d") || 
    // form.getFieldValue("date").isSame(DAY_BEFORE_YESTERDAY, "d")|| 
    // form.getFieldValue("date").isSame(TOMORROW, "d")) {    
    //   props.shouldAllowSave(true)
    // } 
  };

  const onReset = () => {
    form.resetFields()
    props.onFormUpdate(null)
    props.appRef.current && props.appRef.current.scrollIntoView()
    props.shouldAllowSave(false)
  };

  const onSerialNumberChange = () => {
    props.shouldAllowSave(false)
    if (form.getFieldValue("serial").length > 4) {
      form.setFieldsValue({
        "serial": Number(form.getFieldValue("serial").toString().substring(0, 4))
      })

    }
  }
  const disabledDate = (current) => {
    // Can not select days before today and today
    return  current > moment().add(1, "days")
  }

  const handleLanguageChange = (value) => {
    props.shouldAllowSave(false)
    let languageName = value.split("(")[1]
    props.setLanguage(value.split("(")[0])
    languageName = languageName.substr(0, languageName.length - 1)
    const languageMap = translations.find(translation => translation.language === languageName)
    setCurrentLanguage(languageMap)
    formFieldsToMap.forEach(fieldName => {
      form.setFieldsValue({
        [fieldName]: languageMap[fieldName]
      })
    })
    setDescription(`*${value.split(" ")[0].toUpperCase()} : ${moment(form.getFieldValue("date")).format("DD-MM-YYYY")} : DAILY SANDESH*`)
    setDateDetails({
      date: moment(form.getFieldValue("date")).format("DD-MM-YYYY"),
      weekDay: weekDays[moment(form.getFieldValue("date")).format("dddd")][value.split(" ")[0]]
    })
    setSignature(languageMap["signature"])
  }
  return (
    <>
      <Form className="formClass" {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="date" label="Date" rules={[{ required: true, message: "Date is required" }]}>
          <DatePicker
            onChange={()=> props.shouldAllowSave(false)}
            value={moment(form.getFieldValue("date")).format("DD-MM-YYYY")}
            format={"DD/MM/YYYY"} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item name="serial" label="S.R. No." rules={[{ required: true, min: 4, max: 4, message: "Serial number must be of 4 digits" }]}>
          <Input style={{ "width": "137px" }} type={"number"} value={form.getFieldValue("serial")} maxLength={4} onChange={onSerialNumberChange} />
        </Form.Item>
        <Form.Item name="language" label="Language" rules={[{ required: true, message: "Language is required" }]}>
          <Select
            placeholder="Select your language"
            allowClear
            onChange={handleLanguageChange}
            style={{ "width": "200px" }}
          >
            {languages.sort((a, b) => {
              if (b > a) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            }).map((language) => (
              <Option value={language} key={language}>{language}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="title" label="Title">
          <Input disabled={true} style={{ "width": "200px" }} />
        </Form.Item>
        <Form.Item name="body" label="Body" rules={[{ required: true, message: "Message body is required" }]}>
          <TextArea onChange={()=> props.shouldAllowSave(false)} rows={4} style={{ resize: "none" }} />
        </Form.Item>
        <Form.Item name="author" label="Author">
          <Input disabled={true} style={{ "width": "200px" }} />
        </Form.Item>
        <div className="formButtons">
          <Form.Item {...tailLayout}>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Format
            </Button>
          </Form.Item>

        </div>
      </Form>
    </>
  );
};
