import React,{ useState } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import moment from "moment"
import "./Form.css"
const { Option } = Select;
const { TextArea } = Input;
import mapper from "./assets/languageMapper.json"
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 4 },
}

export const SpecialSandesh =  (props) => {
  const {translations,/*intlCodes,*/ weekDays,languages} = mapper
  const [form] = Form.useForm()
  const [dateDetails,setDateDetails] = useState({weekDay:"",date:""})
  const [description,setDescription] = useState("")
  const [signature,setSignature] = useState("")
  const [,setFormValues] = useState() 
  const [paraCount, setParaCount] = useState(1)
  const [para, setPara] = useState([])  
  const formFieldsToMap = [
    // "date",
    // "serial",
    "language",
    "title",
    "body",
    "author"
  ]
  const onFinish = (event) => {
    console.log(form.getFieldValue("para1"))
    let formattedBody = form.getFieldValue("body")
      .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, " ");
    formattedBody = formattedBody.replace(/\n/g, " ")
    formattedBody = formattedBody.replace(/  +/g, " ")
    props.onFormUpdate({...event, ...dateDetails, description, body:formattedBody,signature})
    setFormValues(event)
    // localStorage.setItem("form",JSON.stringify(values))
  };

  const onReset = () => {
    form.resetFields()
    props.onFormUpdate(null)
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().subtract(4, "days")
  }

  const deletePara = (event) => {
    console.log(event.target.parentElement.dataset.id)
    setPara(para.filter((eachPara,index)=> {console.log(index);return index !== event.target.parentElement.dataset.id}))
    setParaCount(paraCount === 1? 1: paraCount - 1)
  }
  const textArea = () => {
    const name = "para" + paraCount
    const label = "Para" + paraCount
    setPara([ ...para, <>
      <Form.Item  name={name} key={name} label={label}  rules={[{ required: true }]}>
        <TextArea rows={4} style={{resize:"none"}}/>
      </Form.Item>
      <div className="ant-row ant-form-item" style={{rowGap: "0px"}}>
        <div className="ant-col ant-col-8 ant-form-item-label"></div>
        <Button data-id={paraCount} onClick={deletePara}>Close</Button>
      </div>
    </>])
    setParaCount(paraCount + 1)
  }
  
  const handleLanguageChange = (value) => {
    let languageName = value.split(" ")[1]
    languageName = languageName.substr(1,languageName.length - 2)
    console.log(languageName)
    // const langIndex = translations.findIndex(translation=> translation.language === languageName) 
    const languageMap = translations.find(translation=> translation.language=== languageName)
    formFieldsToMap.forEach(fieldName => {
      form.setFieldsValue({
        [fieldName]:languageMap[fieldName]
      })
    })
    console.log(form.getFieldValue("para1"))
    setDescription(`${value.split(" ")[0].toUpperCase()} : ${moment().format("DD-MM-YYYY")} : DAILY SANDESH`)
    setDateDetails({date:moment(form.getFieldValue("date")).format("DD-MM-YYYY"), 
      weekDay:weekDays[moment(form.getFieldValue("date")).format("dddd")][value.split(" ")[0]]})
    setSignature(languageMap["signature"])
  }
  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="date" label="Date" rules={[{ required: true }]}>
          <DatePicker value={moment(new Date(Date.now()).toLocaleDateString(), "DD/MM/YYYY")}
            format={"DD/MM/YYYY"} disabledDate={disabledDate}/>
        </Form.Item>
        <Form.Item name="serial" label="S.R. No." rules={[{ required: true, min:1, max:4, message:"Serial number must be of 4 digits" }]}>
          <Input type={"number"} />
        </Form.Item>
        <Form.Item name="language" label="Language" rules={[{ required: true }]}>
          <Select
            placeholder="Select a option and change input text above"
            allowClear
            onChange={handleLanguageChange}
          >
            {languages.sort((a,b)=>{if (b > a) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;}).map((language) => (
              <Option value={language} key={language}>{language}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="title" label="Title">
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item name="body" label="Body" rules={[{ required: true }]}>
          <TextArea rows={4} style={{resize:"none"}}/>
        </Form.Item>
        {para}
        <div className="ant-row ant-form-item" style={{rowGap: "0px"}}>
          <div className="ant-col ant-col-8 ant-form-item-label"></div>
          <Button onClick={textArea}>Add Para</Button>
        </div>
        <Form.Item name="author" label="Author">
          <Input disabled={true}/>
        </Form.Item>
        <>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
          Format
            </Button>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button htmlType="button" onClick={onReset}>
          Reset
            </Button>
          </Form.Item>
        </>
      </Form>
      {/* <div>
        {formValues}
      </div> */}
    </>
  );
};
