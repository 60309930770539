import React, { useState, useRef } from "react"
import "./App.css"
import { SandeshForm } from "./Form.jsx"
import PreviewForm from "./PreviewForm.jsx"
import { Image } from "antd";
import Img from "./assets/20220315_185245_0000.png"
function App() {
  const [Form, setForm] = useState()
  const ref = useRef(null)
  const [allowSave, setAllowSave] = useState(false)
  const [languageName, setLanguageName] = useState({})
  const [formattedDate, setFormattedDate] = useState("")
  const shouldAllowSave = (shouldSave) => {
    setAllowSave(shouldSave)
  }
  const setLanguage = (lang) => {
    setLanguageName(lang)
  }
  const saveFormattedDate = (date) => {
    setFormattedDate(date)
  }
  return (
    <div className="App" ref={ref}>
      <div className="banner-image-container">
        <Image
          src={Img}
          preview={false}
          className="banner-image"
        />
      </div>
      <SandeshForm appRef={ref} setLanguage={setLanguage} className="formTabContainer"
        shouldAllowSave={shouldAllowSave} onFormUpdate={(form) => { setForm(form) }}
        saveFormattedDate={saveFormattedDate} />
      <PreviewForm form={Form} allowSave={allowSave} languageName={languageName} formattedDate={formattedDate} />
    </div>
  );
}

export default App;
