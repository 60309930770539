import React, {useEffect, useState,useRef} from "react"
import {message,Input,Button} from "antd"
import { WhatsAppOutlined } from "@ant-design/icons";
import {Message,Language} from "./core"
import moment from "moment"
const { TextArea } = Input
export default function PreviewForm(props) {
  let languageAPI = new Language()
  var messageAPI = new Message()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  const [formatContent,setFormatContent] = useState("")
  const [textAreaStyle, setTextAreaStyle] = useState({})

  useEffect(async () => {
    myRef.current && executeScroll()
    setTextAreaStyle({
      textAlign:  (props.form && (props.form.description.includes("URDU") || props.form.description.includes("SINDHI"))) ? "right":"left"
    })
    const text = props.form ?`${props.form.description}\n
${props.form.serial}
${props.form.language}
${props.form.date}
${props.form.weekDay}\n\n
${props.form.title}\n
${props.form.body}\n
${props.form.author}`:""
    setFormatContent( text )
    
  },[props.form])
  const showMessage = (text) => {
    message.config({top:"50%"})
    message.success(text,2)
  }

  const replaceMessage = async () => {
    await languageAPI.findAll()
    const language = await languageAPI.get().find(language => {
      return language.name.trim() === props.languageName.trim()
    })
    const messageBody = {        
      serialNumber: props.form.serial,
      language: language.id,
      messageBody: props.form.body,
      title: props.form.title,
      author: props.form.author,
      writtenDate: props.formattedDate,
      isCurrent: true
    }
    await messageAPI.create(messageBody)
    setIsModalOpen(false)
    showMessage("Message replaced successfully for " + moment(props.formattedDate).format("DD MMM, YYYY")
     + " in " + language.name)
  }
  const saveMessage = async () => {
    await languageAPI.findAll()
    const language = await languageAPI.get().find(language => {
      return language.name.trim() === props.languageName.trim()
    })
    const message = await messageAPI.find(props.formattedDate,language.id)
    if(message?.data?.length) {
      setIsModalOpen(true)
      return
    }
    const messageBody = {        
      serialNumber: props.form.serial,
      language: language.id,
      messageBody: props.form.body,
      title: props.form.title,
      author: props.form.author,
      writtenDate: props.formattedDate,
      isCurrent: true
    }
    await messageAPI.create(messageBody)
    showMessage("Message saved successfully for \n" + moment(props.formattedDate).format("DD MMM, YYYY")
    + " in " + language.name)
  };
  return (
    props.form ?  (
      <>
        <div ref={myRef}>
          <div style={{"border":"1px solid white","padding":"30px","position":"relative",paddingBottom:"20px"}}>
            <TextArea disabled style={textAreaStyle} value={formatContent} rows={15} maxLength={3000}/>
            <div style={{
              position:"absolute",
              top:"20%", 
              left:"20%", 
              height:"160px",
              width:"220px", 
              textAlign:"center",
              backgroundColor:"#fff2f0",
              border:"1px solid #ffccc7",
              padding:"10px 0px",
              display: isModalOpen && props.allowSave ? "block":"none"
            }}>
              <p>Message is already saved for <br/><b>{moment(props.formattedDate).format("DD MMM, YYYY")}</b>
               &nbsp;in <br/><b>{props.form.language}</b>.<br/>
               Would you like to replace it?</p>
              <div style={{
                display:"flex",
                gap:"10px", 
                justifyContent:"center"
              }}>
                <Button onClick={replaceMessage} style={{
                  backgroundColor:"#fa541c",
                  color:"white",
                  fontWeight:"bold"
                }}>Replace</Button>
                <Button onClick={() => {setIsModalOpen(false)}}>Cancel</Button>
              </div>
            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end",gap:"10px", marginTop:"15px"}}>
              <Button type="primary" onClick={saveMessage} style={{display: !props.allowSave ? "none": "block"}}>
              Save
              </Button>
              <Button onClick={() => {navigator.clipboard.writeText(formatContent),
              showMessage("Content has copied to clipboard")}}>
                Copy
              </Button>
              <Button style={{backgroundColor:"#3AC371",color:"white", fontWeight:"bold"}}
                onClick={()=> window.open("https://api.whatsapp.com/send?text=" +encodeURI(formatContent)) }>
                <WhatsAppOutlined/>Share to Whatsapp</Button>
            </div>
          </div>
        </div>
      
      </>):<></>
  )
}
